export function phrase_choices(number_of_choices) {
  if (number_of_choices == 0) { return "(No Choices)"; }
  if (number_of_choices == 1) { return "(1 Choice)"; }
  return "(" + number_of_choices + " Choice)";
}

export function phrase_items(number_of_items) {
  if (number_of_items == 0) { return "(No Items)"; }
  if (number_of_items == 1) { return "(1 Item)"; }
  return "(" + number_of_items + " Items)";
}
